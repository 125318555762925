import * as React from 'react';




function PageHeader({ className, children }) {


    return <h1 className={ className.concat(" text-4xl font-bold")} >{children}</h1>
}

export default PageHeader
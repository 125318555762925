
import * as React from 'react';



function PageSection({ children }) {

    return <div className="h-[calc(100vh-87px)] p-6 flex flex-col w-full bg-[#f8f8f8] xl:justify-center">
        {children}
    </div>
}

export default PageSection